<template>
  <div id="blog" class="contents">
    <MessageBox ref="msgBox" />
    <h1 title="技術ブログ">BLOG</h1>
    <div class="intro">
      <h2>現在準備中</h2>
      <p>ここでは、UnityやPlayCanvas、様々なセンサーに関する<br class="no-SP">他には無い情報を発信していければと思います。</p>
    </div>

    <div class="content">
      <section>
        <div class="fillter">
          <div class="sticky">
            <h2>{{ activeCategory }}</h2>
            <ul>
              <li><router-link to="/blog">全て</router-link></li>
              <li v-for="cat in categories" :key="cat.id" :class="{active: $route.params.id === cat.id}">
                <router-link :to="{ name:'blog', params: {id: cat.id}}">{{ cat.name }}</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="post">
          <article v-for="data in categoryPostData($route.params.id)" :key="data.id">
            <h3>{{ data.title }}</h3>
              <div class="body" v-if="data.content" v-html="data.content">
              </div>
          </article>
        </div>
      </section>
    </div>

    <ToContact />
  </div>
</template>

<script>
// @ is an alias to /src

import axios from 'axios'

export default {
  name: 'Blog',
  data: function () {
    return {
      baseAPIUrl: 'https://sorashiro.com/wp-json/wp/v2/',
      activeCategory: '',
      postData: [],
      categories: []
    }
  },
  metaInfo: {
    title: '技術ブログ',
    bodyAttrs: {
      id: 'pg-blog'
    },
    link: [
      { rel: 'canonical', href: 'https://sorashiro.com/blog/' }
    ]
  },
  mounted: function () {
    this.loadPostData()
    this.$refs.msgBox.show('loading', '')
  },
  computed: {
    baseUrl () {
      return this.$store.getters.baseUrl
    },
    categoryPostData () {
      return function (catId) {
        var r = []
        if (catId) {
          for (var post of this.postData) {
            // console.log(post.category)
            for (const key in post.category) {
              if (post.category[key] === catId) {
                r.push(post)
                break
              }
            }
          }
        } else {
          r = this.postData
        }
        return r
      }
    }
  },
  methods: {
    init: function () {
      this.$refs.msgBox.show('', '')

      this.$nextTick(function () {
        this.initGsap(this.$isMobile())
      })
    },
    getWorksDate: function (d) {
      var r = new Date(d)
      r = r.getFullYear() + '.' + r.getMonth() + 1
      return r
    },
    getCategoryName: function (id) {
      if (id) {
        for (var k in this.categories) {
          if (this.categories[k].id === id) {
            return this.categories[k].name
          }
        }
      }
      return '全て'
    },
    loadPostData: function () {
      const url1 = this.baseAPIUrl + 'blog'
      const url2 = this.baseAPIUrl + 'category_blog'
      var tempPost = []
      var tempCat = []

      Promise.resolve()
        .then(() => axios.get(url1))
        .then((response) => {
          // 1個目の通信が成功
          for (var post of response.data) {
            var data = {
              id: post.id,
              date: post.date,
              title: post.title.rendered,
              content: post.content.rendered,
              category: post.category_blog
            }
            tempPost.push(data)
          }
          this.postData = tempPost

          return axios.get(url2)
        })
        .then(response => {
          // 2個目の通信が成功
          for (var post of response.data) {
            var data = {
              id: post.id,
              name: post.name
            }
            tempCat.push(data)
          }
          this.categories = tempCat
        })
        .catch(e => {
          // エラーが発生
          console.log(e)
        })
        .finally(() => {
          // 最終的な処理を書く
          // console.log('データ読み込み完了')
          this.activeCategory = this.getCategoryName(this.$route.params.id)
          this.init()
        })
    }
  },
  beforeDestroy: function () {
    this.removeGsap()
  }
}
</script>
